import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import './style.scss'
import { isMobile } from "react-device-detect";
import BannerAirdrop from "../banner-airdrop";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import { Modal, Input } from 'antd';
import {
    useAccount,
    useDisconnect,
} from "wagmi";
import { AppHome } from "../../api/appHome";

const Header = () => {
    const naigation = useNavigate();
    const { address } = useAccount();
    const { open } = useWeb3Modal();
    const { disconnect } = useDisconnect();
    const [dataTime, setDataTime]: any = useState("")
    const [isModalOpen, setIsModalOpen] = useState(false);
    let timeNowStam = new Date().getTime()
    let timeStart = Number(dataTime?.startTime) * 1000
    let timeEnd = Number(dataTime?.endTime) * 1000

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const handleLaunchApp = () => {
        window.open('https://meyfi.io/')
    }

    const handleHome = () => {
        naigation("/")
    }

    const location = useLocation()
    let pathUrl = location?.pathname.split("/")
    let pathComing = pathUrl[1] || ''

    function gotoMenu() {
        naigation(`/`)
    }

    const handleConnect = () => {
        open();
    };

    const handleDisConnect = () => {
        disconnect();
        setIsModalOpen(false)
    }

    const getDataAir = async () => {
        let params = {
            address: address
        }
        try {
            let res = await AppHome.getAirdropUser(params);
            if (res.data.data !== undefined) {
                setDataTime(res.data.data)
            }
        } catch (error) {
            console.log("Error: ", error);
        }
    }

    useEffect(() => {
        getDataAir()
    }, []);

    return (
        <>
            <div className="header-landing">
                {pathComing === "" ?
                    <>
                        {timeNowStam < timeStart || timeNowStam > timeEnd ?
                            <>
                                {""}
                            </>
                            :
                            <>
                                <BannerAirdrop />
                            </>
                        }
                    </>
                    :
                    <>
                        {""}
                    </>
                }
                <div className="container">
                    <div className="content-header-land">
                        <div className="header-land-left">
                            <div className="logo-header-land">
                                {isMobile ? (
                                    <div className="img-logo-land-mo" onClick={handleHome}>
                                        <img src="./images/main-logo-mo.png" alt="" />
                                    </div>
                                ) : (
                                    <div className="img-logo-land" onClick={handleHome}>
                                        <img src="./images/main-logo.png" alt="" />
                                    </div>
                                )}

                            </div>
                            {pathComing === 'coming-soon' ? ('') : (
                                <>
                                    <div className="item-header-land">
                                        <a onClick={gotoMenu} href="#feature">Features</a>
                                    </div>
                                    <div className="item-header-land">
                                        <a onClick={gotoMenu} href="#work">How it work</a>
                                    </div>
                                    <div className="item-header-land">
                                        <a onClick={gotoMenu} href="#about">About</a>
                                    </div>
                                    <div className="item-header-land">
                                        <a target="_blank" href="https://docs.mey.network/mey-network">Docs</a>
                                    </div>
                                </>
                            )}
                        </div>
                        <div className="header-land-right">
                            {pathComing === "airdrop" ?
                                <>
                                    {address ?
                                        <>
                                            <div className="connect-wallet">
                                                <button className="btn btn-connect" onClick={showModal}>
                                                    {address?.slice(0, 4)}...{address?.slice(-4)}
                                                </button>
                                            </div>
                                        </>
                                        :
                                        <>
                                            <div className="connect-wallet">
                                                <button className="btn btn-connect" onClick={handleConnect}>
                                                    Connect Wallet
                                                </button>
                                            </div>
                                        </>
                                    }
                                </>
                                :
                                <>
                                    <button className="btn-launch-app" onClick={handleLaunchApp}>Launch App</button>
                                </>
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Modal className="modal-dis" title="Disconnect wallet" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
                <div className="profile-user">
                    <div className="connect-wallet">
                        <button className="btn btn-connect" onClick={handleDisConnect}>
                            Disconnect
                        </button>
                    </div>
                </div>
            </Modal>
        </>
    )
}

export default Header;