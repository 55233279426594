import React, { useEffect } from "react";
import { TypeAnimation } from "react-type-animation";
import { isMobile } from 'react-device-detect';
import AOS from 'aos';
import 'aos/dist/aos.css';
import "./style.scss"
import { URL_FB, URL_TELE_GROUP, URL_X, URL_YT } from "../../components/contans";

const HomePage = () => {

    useEffect(() => {
        AOS.init();
    }, []);

    function goToTele() {
        window.open(URL_TELE_GROUP)
    }

    function goToFB() {
        window.open(URL_FB)
    }

    function goToX() {
        window.open(URL_X)
    }

    function goToYT() {
        window.open(URL_YT)
    }

    return (
        <>
            <section className="section-feature-banner">
                <div className="container">
                    <div className="wrap-feature-banner">
                        <div className="box-img">
                            <video
                                autoPlay
                                muted
                                loop
                                className="video-box"
                            >
                                <source src="./images/box2.mp4" />
                            </video>
                        </div>
                        <div className="wrap-dex">
                            {isMobile ?
                                <>
                                    <div className="main-title">
                                        <div className="title-m">
                                            Transforming Real-World Assets into Digital Opportunities
                                        </div>
                                    </div>
                                </>
                                :
                                <>
                                    <div className="title">
                                        <TypeAnimation
                                            style={{ whiteSpace: 'pre-line', height: '154px', display: 'block' }}
                                            sequence={[
                                                "Transforming ",
                                                1000,
                                                `Transforming Real-World Assets into Digital Opportunities`,
                                                1000,
                                                "Transforming ",
                                                1000,
                                                `Transforming Real-World Assets into Digital Opportunities`,
                                                1000,
                                            ]}
                                            wrapper="span"
                                            speed={30}
                                            repeat={Infinity}
                                        />
                                    </div>
                                </>
                            }
                            <div className="desc" data-aos="fade-up" data-aos-duration="1000">
                                An innovative ecosystem powered by MeyFi and Meychain, enabling secure, scalable, and efficient asset tokenization and decentralized finance.
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="secion-feature-introducing">
                <div className="row-images-intro" id="meyfi">
                    <img src="./images/img-intro.png" alt="" />
                </div>
                <div className="container">
                    <div className="wrap-feature-introducing" id='about'>
                        <div className="main-title" data-aos="fade-up" data-aos-duration="1000">
                            <div className="desc-m">
                                What is Mey Network?
                            </div>
                            <div className="title-m">
                                Introducing Mey Network
                            </div>
                        </div>
                        <div className="desc-page" data-aos="fade-up" data-aos-duration="1200">
                            Mey Network is an integrated blockchain ecosystem designed to bridge the gap between physical assets and the digital world. By combining the power of Meychain—a dedicated Layer 1 blockchain for Real-World Assets (RWAs)—and MeyFi, our decentralized finance platform, Mey Network enables seamless tokenization, trading, and management of assets in a secure, scalable environment.
                        </div>
                        <div className="box-img" data-aos="fade-up" data-aos-duration="1400">
                            <img src="./images/banner-inro1.png" alt="" />
                        </div>
                    </div>
                </div>
            </section>
            <section className="section-feature-building">
                <div className="container">
                    <div className="wrap-feature-building" id="feature">
                        <div className="main-title" data-aos="fade-up" data-aos-duration="1000">
                            <div className="desc-m">
                                Key Components
                            </div>
                            <div className="title-m">
                                The Building Blocks of Mey Network
                            </div>
                        </div>
                        <div className="group-building">
                            <div className="columns">
                                <div className="colum w-4">
                                    <div className="page-resize" data-aos="zoom-in" data-aos-duration="1200">
                                        <div className="content-row">
                                            <div className="box-img">
                                                <img src="./images/v-1.png" alt="" />
                                            </div>
                                            <div className="title">
                                                Meychain
                                            </div>
                                            <div className="desc">
                                                Our Layer 1 blockchain, specifically designed for the tokenization and secure management of Real-World Assets (RWAs).
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="colum w-4">
                                    <div className="page-resize" data-aos="zoom-in" data-aos-duration="1400">
                                        <div className="content-row">
                                            <div className="box-img">
                                                <img src="./images/v-2.png" alt="" />
                                            </div>
                                            <div className="title">
                                                MeyFi
                                            </div>
                                            <div className="desc">
                                                A powerful decentralized finance (DeFi) platform that enables staking, lending, and trading of tokenized assets within the Mey Network ecosystem.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="colum w-4">
                                    <div className="page-resize" data-aos="zoom-in" data-aos-duration="1600">
                                        <div className="content-row">
                                            <div className="box-img">
                                                <img src="./images/v-3.png" alt="" />
                                            </div>
                                            <div className="title">
                                                Meycoin (MEY)
                                            </div>
                                            <div className="desc">
                                                The native cryptocurrency of Mey Network, facilitating transactions, rewards, and governance across the ecosystem.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section-feature-empower">
                <div className="container">
                    <div className="wrap-feature-empower" id="work">
                        <div className="main-title" data-aos="fade-up" data-aos-duration="1000">
                            <div className="desc-m">
                                How It Works
                            </div>
                            <div className="title-m">
                                How Mey Network Empowers You
                            </div>
                        </div>
                        <div className="group-empower">
                            <div className="columns">
                                <div className="colum w-4">
                                    <div className="page-resize" data-aos="zoom-in" data-aos-duration="1200">
                                        <div className="content-row">
                                            <div className="box-img">
                                                <img src="./images/e-1.png" alt="" />
                                            </div>
                                            <div className="step-row">
                                                Step 1:
                                            </div>
                                            <div className="title">
                                                Tokenize Real-World Assets
                                            </div>
                                            <div className="desc">
                                                Utilize Meychain to securely tokenize physical assets such as real estate, transforming them into digital tokens.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="colum w-4">
                                    <div className="page-resize" data-aos="zoom-in" data-aos-duration="1400">
                                        <div className="content-row">
                                            <div className="box-img">
                                                <img src="./images/e-2.png" alt="" />
                                            </div>
                                            <div className="step-row">
                                                Step 2:
                                            </div>
                                            <div className="title">
                                                Engage in Decentralized Finance
                                            </div>
                                            <div className="desc">
                                                Use MeyFi to access a range of DeFi services, including staking, P2P lending, and trading, all powered by tokenized assets.                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="colum w-4">
                                    <div className="page-resize" data-aos="zoom-in" data-aos-duration="1600">
                                        <div className="content-row">
                                            <div className="box-img">
                                                <img src="./images/e-3.png" alt="" />
                                            </div>
                                            <div className="step-row">
                                                Step 3:
                                            </div>
                                            <div className="title">
                                                Earn and Utilize Meycoin
                                            </div>
                                            <div className="desc">
                                                Participate in the Mey Network ecosystem by contributing resources, validating transactions, and earning Meycoin (MEY) as rewards.                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section-feature-optimized">
                <div className="images-op">
                    <img src="./images/image-op.png" alt="" />
                </div>
                <div className="container">
                    <div className="wrap-feature-optimized">
                        <div className="main-title" data-aos="fade-up" data-aos-duration="1000">
                            <div className="desc-m">
                                Modular Architecture
                            </div>
                            <div className="title-m">
                                Optimized for Performance and Flexibility
                            </div>
                        </div>
                        <div className="desc-page" data-aos="fade-up" data-aos-duration="1200">
                            Mey Network is built on a modular architecture that optimizes performance across its various layers. From secure transaction validation on Meychain to efficient asset management on MeyFi, every component of Mey Network is designed to work seamlessly together, ensuring scalability, security, and interoperability.
                        </div>
                        <div className="box-img" data-aos="zoom-in" data-aos-duration="1400">
                            <img src="./images/banner-op.png" alt="" />
                        </div>
                    </div>
                </div>
            </section>
            {/* <section className="section-feature-action">
                <div className="container">
                    <div className="wrap-feature-action" id="about">
                        <div className="main-title" data-aos="fade-up" data-aos-duration="1000">
                            <div className="desc-m">
                                Real-World Applications
                            </div>
                            <div className="title-m">
                                Mey Network in Action
                            </div>
                        </div>
                        <div className="desc-page" data-aos="fade-up" data-aos-duration="1200">
                            Explore how Mey Network is revolutionizing industries by bringing real-world assets into the digital realm.
                        </div>
                        <div className="group-action">
                            <div className="columns">
                                <div className="colum w-4">
                                    <div className="page-resize" data-aos="zoom-in" data-aos-duration="1400">
                                        <div className="content-row">
                                            <div className="box-img">
                                                <img src="./images/a-1.png" alt="" />
                                            </div>
                                            <div className="title">
                                                Real Estate Tokenization
                                            </div>
                                            <div className="desc">
                                                Enabling fractional ownership and trading of real estate, increasing liquidity and investment opportunities.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="colum w-4">
                                    <div className="page-resize" data-aos="zoom-in" data-aos-duration="1600">
                                        <div className="content-row">
                                            <div className="box-img">
                                                <img src="./images/a-2.png" alt="" />
                                            </div>
                                            <div className="title">
                                                AR/VR Integration
                                            </div>
                                            <div className="desc">
                                                Supporting immersive property viewings through advanced AR/VR technology.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="colum w-4">
                                    <div className="page-resize" data-aos="zoom-in" data-aos-duration="1800">
                                        <div className="content-row">
                                            <div className="box-img">
                                                <img src="./images/a-3.png" alt="" />
                                            </div>
                                            <div className="title">
                                                Meycoin (DeFi Services)
                                            </div>
                                            <div className="desc">
                                                Offering a range of decentralized finance applications, including lending, staking, and trading, all powered by tokenized assets."
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section> */}
            <section className="section-feature-ecosystem">
                <div className="container">
                    <div className="wrap-feature-ecosystem">
                        <div className="com1">
                            <img src="/images/a-com1.png" alt="" />

                        </div>
                        <div className="main-title" data-aos="fade-up" data-aos-duration="1000">
                            <div className="title-m">
                                Community and Ecosystem
                            </div>
                        </div>
                        <div className="desc-page" data-aos="fade-up" data-aos-duration="1200">
                            Be part of a global community that’s driving the future of real-world asset management. Connect with developers, investors, and innovators who are building on Mey Network.
                        </div>
                        <div className="group-ecosystem" data-aos="fade-up" data-aos-duration="1400">
                            <div className="title">
                                Partners and Collaborators
                            </div>
                            <div className="list-marquee">
                                <div className="item">
                                    <img src="./images/th-1.png" alt="" />
                                </div>
                                <div className="item">
                                    <img src="./images/th-2.png" alt="" />
                                </div>
                                <div className="item">
                                    <img src="./images/th-3.png" alt="" />
                                </div>
                                <div className="item">
                                    <img src="./images/th-4.png" alt="" />
                                </div>
                                <div className="item">
                                    <img src="./images/th-6.png" alt="" />
                                </div>
                                <div className="item">
                                    <img src="./images/th-7.png" alt="" />
                                </div>
                                <div className="item">
                                    <img src="./images/th-8.png" alt="" />
                                </div>
                                <div className="item">
                                    <img src="./images/th-9.png" alt="" />
                                </div>
                                <div className="item">
                                    <img src="./images/th-10.png" alt="" />
                                </div>
                                <div className="item">
                                    <img src="./images/th-11.png" alt="" />
                                </div>
                                <div className="item">
                                    <img src="./images/th-12.png" alt="" />
                                </div>
                                <div className="item">
                                    <img src="./images/th-13.png" alt="" />
                                </div>
                                <div className="item">
                                    <img src="./images/th-14.png" alt="" />
                                </div>
                                <div className="item">
                                    <img src="./images/th-15.png" alt="" />
                                </div>
                                <div className="item">
                                    <img src="./images/th-16.png" alt="" />
                                </div>
                                <div className="item">
                                    <img src="./images/th-17.png" alt="" />
                                </div>
                                <div className="item">
                                    <img src="./images/th-18.png" alt="" />
                                </div>
                                <div className="item">
                                    <img src="./images/th-19.png" alt="" />
                                </div>
                                <div className="item">
                                    <img src="./images/th-20.png" alt="" />
                                </div>
                                <div className="item">
                                    <img src="./images/th-21.png" alt="" />
                                </div>
                            </div>
                            <div className="title mar-top-30" data-aos="fade-up" data-aos-duration="1800">
                                Community Links
                            </div>
                            <div className="group-social" data-aos="fade-up" data-aos-duration="2000">
                                <div className="columns">
                                    <div className="colum w-3">
                                        <div className="colum-resize">
                                            <div className="content-row" onClick={goToTele}>
                                                <div className="box-img">
                                                    <img src="./images/re-1.png" alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="colum w-3">
                                        <div className="colum-resize">
                                            <div className="content-row" onClick={goToFB}>
                                                <div className="box-img">
                                                    <img src="./images/re-2.png" alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="colum w-3">
                                        <div className="colum-resize">
                                            <div className="content-row" onClick={goToX}>
                                                <div className="box-img">
                                                    <img src="./images/re-3.png" alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="colum w-3">
                                        <div className="colum-resize">
                                            <div className="content-row" onClick={goToYT}>
                                                <div className="box-img">
                                                    <img src="./images/re-4.png" alt="" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="group-mey-network" data-aos="fade-up" data-aos-duration="2200">
                                <div className="box-img">
                                    <img className="rotating" src="./images/mey-box2.gif" alt="" />
                                </div>
                                <div className="title">
                                    Start Your Journey with Mey Network
                                </div>
                                {/* <div className="group-button">
                                    <button className="btn-launch-app"><img src="./images/rocket.png" alt="" /> Get Started</button>
                                    <button className="btn-launch-app res-button">Explore Meychain and MeyFi</button>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
export default HomePage