import React, { useState } from "react";
import "./style.scss"
import { useNavigate } from "react-router-dom";

const BannerAirdrop = () => {
    const naigation = useNavigate()
    const [showBanner, setShowBanner] = useState(false)

    const handleShow = () => {
        setShowBanner(true)
    }

    const handleLaunchAir = () => {
        naigation("/airdrop")
    }

    return (
        <>
            {!showBanner &&
                <div className="main-banner-air">
                    <div className="container">
                        <div className="wrap-air">
                            <div className="left-row">
                                <div className="text">
                                    Phishing Warning: Please make sure you are visiting
                                    <a className="link-web" href="https://mey.network/" target="_blank" rel="noopener noreferrer">
                                        {" "}https://mey.network/{" "}
                                    </a>
                                    and
                                    <a className="link-web" href="https://meyfi.io/" target="_blank" rel="noopener noreferrer">
                                        {" "}https://meyfi.io/{" "}
                                    </a>

                                    Check the URL carefully.
                                </div>
                            </div>
                            <div className="right-row">
                                <div className="group-button-claim">
                                    {/* <button className="btn-claim-air" onClick={handleLaunchAir}>
                                        CLAIM NOW
                                    </button> */}
                                    <img onClick={handleShow} src="./images/Close-x.png" alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }

        </>
    )
}
export default BannerAirdrop