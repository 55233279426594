import React, { useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";

import './style.scss'
import { URL_DOCS, URL_FB, URL_TELE_GROUP, URL_X, URL_YT } from "../contans";
import { ChangeEvent } from "react";
import { Noti } from '../Noti/toast';

interface TokenProps {
    address: string;
    symbol: string;
    decimals: number;
}


const Footer = () => {
    const [value, setValue]: any = useState('')
    const location = useLocation()
    let pathUrl = location?.pathname.split("/")
    let pathComing = pathUrl[1] || ''
    const naigation = useNavigate()

    function validateEmail(email: any) {
        var re = /\S+@\S+\.\S+/;
        return re.test(email);
    }
    function subEmail() {
        let check = validateEmail(value)
        if (check) {
            Noti('success', 'Subscribe successfully', 2000)
        } else {
            Noti('error', 'Subscribe Error', 2000)
        }
    }

    function handleChanInput(e: any) {
        setValue(e?.target?.value)
    }

    const addTokenToWallet = async (props: TokenProps) => {
        const { address, symbol, decimals }: any = props;
        const provider: any = (window as Window).ethereum;
        try {
            const wasAdded = await provider.request({
                method: "wallet_watchAsset",
                params: {
                    type: "ERC20",
                    options: {
                        address,
                        symbol,
                        decimals,
                    },
                },
            });
            if (!wasAdded) {
                Noti("success", "Add token success", 3000)
            }
        } catch (error: any) {
            Noti("error", error?.message, 3000)
        }
    };

    return (
        <>
            {pathComing === 'coming-soon' ? (
                <>
                    {/* <footer className="footer new">
                        <div className="container coming-f">
                            <div className="wrap-footer">
                                <div className="footer-left">
                                    <div className="title-cm">
                                        Join Our Community
                                    </div>
                                    <div className="list-social">
                                        <div className="item" onClick={goToTele}>
                                            <img src="./images/f-1.png" alt="" />
                                        </div>
                                        <div className="item" onClick={goToX}>
                                            <img src="./images/f-2.png" alt="" />
                                        </div>
                                        <div className="item" onClick={goToFB}>
                                            <img src="./images/f-3.png" alt="" />
                                        </div>
                                        <div className="item" onClick={goToYT}>
                                            <img src="./images/f-4.png" alt="" />
                                        </div>
                                    </div>
                                </div>

                            </div>

                        </div>
                    </footer> */}
                </>
            ) : (
                <>
                    <footer className="footer">
                        <div className="container">
                            <div className="wrap-footer">
                                <div className="footer-left">
                                    <div className="footer-left-top">
                                        <img className='logo' src="./images/logo-ft.png" alt="" />
                                        <p className='title-text'>Revolutionizing Real Estate Investment</p>
                                        <div className='email-text'>
                                            <img src="./images/mail.png" alt="" />
                                            <p>contact@mey.network</p>
                                        </div>
                                    </div>
                                    <div className='footer-left-bottom'>
                                        {/* <div className='element'>
                                            <img src="./images/mey-footer.png" />
                                            <p>{`$${0.005}`}</p>
                                        </div>  */}
                                        <div className='element' onClick={() =>
                                            addTokenToWallet({
                                                address: `0x8bFAc1b375bf2894D6F12fb2Eb48B1C1a7916789`,
                                                symbol: `MEY`,
                                                decimals: 18,
                                            })
                                        }
                                        >
                                            <img src="./images/meta-mask.png" />
                                            <p>Add to wallet</p>
                                        </div>
                                    </div>
                                    {/* <div className="list-social">
                                        <div className="item" onClick={goToTele}>
                                            <img src="./images/f-1.png" alt="" />
                                        </div>
                                        <div className="item" onClick={goToX}>
                                            <img src="./images/f-2.png" alt="" />
                                        </div>
                                        <div className="item" onClick={goToFB}>
                                            <img src="./images/f-3.png" alt="" />
                                        </div>
                                        <div className="item" onClick={goToYT}>
                                            <img src="./images/f-4.png" alt="" />
                                        </div>
                                    </div> */}
                                </div>
                                <div className="footer-right">
                                    <div className='col'>
                                        <div className='title'>
                                            <p className='title-text'>
                                                Products
                                            </p>
                                        </div>
                                        <div className='link'>
                                            <a className='link-text' href='https://meyfi.io/' target='blank' >
                                                MeyFi
                                            </a>
                                            <a className='link-text' href='/coming-soon'>
                                                Mey Chain
                                            </a>
                                        </div>
                                    </div>
                                    <div className='col'>
                                        <div className='title'>
                                            <p className='title-text'>
                                                Legal
                                            </p>
                                        </div>
                                        <div className='link'>
                                            <a className='link-text' href='/PrivacyPolicy'>
                                                Privacy Policy
                                            </a>
                                            <a className='link-text' href='/Term'>
                                                Term of Use
                                            </a>
                                            <a className='link-text' href='/PrivacyPolicy'>
                                                ALM Policy
                                            </a>
                                        </div>
                                    </div>
                                    <div className='col'>
                                        <div className='title'>
                                            <p className='title-text'>
                                                Community
                                            </p>
                                        </div>
                                        <div className='link'>
                                            <a className='link-text' target='_blank' href={URL_X}>
                                                <img src="./images/x-footer.png" alt="" />X
                                            </a>
                                            <a className='link-text' target='_blank' href={URL_TELE_GROUP}>
                                                <img src="./images/telegram-footer.png" alt="" />Telegram
                                            </a>
                                            <a className='link-text' target='_blank' href={URL_YT}>
                                                <img src="./images/youtube-footer.png" alt="" />Youtube
                                            </a>
                                            <a className='link-text' target='_blank' href={URL_FB}>
                                                <img src="./images/facebook-footer.png" alt="" />Facebook
                                            </a>
                                        </div>
                                    </div>
                                    <div className='col'>
                                        <div className='title'>
                                            <p className='title-text'>
                                                Resource
                                            </p>
                                        </div>
                                        <div className='link'>
                                            <a className='link-text' target='_blank' href='https://drive.google.com/file/d/1ncSC-3RrtAk7msVHi90YsZ7flEIILyOv/view'>
                                                Whitepaper
                                            </a>
                                            <a className='link-text' target='_blank' href='/MEY-Tokenomic.pdf'>
                                                Tokenomic
                                            </a>
                                            <a className='link-text' href='/coming-soon'>
                                                Roadmap
                                            </a>
                                            <a className='link-text' target='_blank' href={URL_DOCS}>
                                                Docs
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="copy-right">
                                <p>
                                    MeyNetwork, All rights reserved.
                                </p>
                                {/* <div className="right">
                                    <ul className="list-menu">
                                        <li>
                                            <a target="_blank" href="https://docs.mey.network/mey-network">
                                                Docs
                                            </a>
                                        </li>
                                        <li onClick={gotoPolicy}>
                                            <a>
                                                Privacy Policy
                                            </a>
                                        </li>
                                        <li onClick={gotoTerms}>
                                            <a>
                                                Term of Conditions
                                            </a>
                                        </li>
                                    </ul>
                                </div> */}
                            </div>
                        </div>
                        {/* <div className="img-ft">
                            <img src="/images/wave1.png" alt="" />
                        </div> */}
                    </footer>
                    {/* <footer className="footer cus">
                        <div className="container">
                            <div className="wrap-footer">
                                <div className="footer-left">
                                    <div className="box-img">
                                        <img src="./images/logo-ft.png" alt="" />
                                    </div>
                                    <div className="list-social">
                                        <div className="item" onClick={goToTele}>
                                            <img src="./images/f-1.png" alt="" />
                                        </div>
                                        <div className="item" onClick={goToX}>
                                            <img src="./images/f-2.png" alt="" />
                                        </div>
                                        <div className="item" onClick={goToFB}>
                                            <img src="./images/f-3.png" alt="" />
                                        </div>
                                        <div className="item" onClick={goToYT}>
                                            <img src="./images/f-4.png" alt="" />
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div className="copy-right">
                                <div className="left">
                                    Mey Network, All rights reserved.
                                </div>

                            </div>
                        </div>
                        <div className="img-ft">
                            <img src="/images/wave1.png" alt="" />
                        </div>
                    </footer> */}
                </>
            )}
        </>



    )
}

export default Footer;